import React, { useState, useEffect } from "react";
import axios from "axios";
import "./myservices.css"; // Import your CSS file

const ServiceList = () => {
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [clientInfo, setClientInfo] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
  });

  useEffect(() => {
    // Fetch the list of services using Axios
    axios
      .get("https://theyardsquad.net/automation/services/getallservices.php")
      .then((response) => {
        setServices(response.data); // Assuming the API returns an array
      })
      .catch((error) => {
        console.error("Error fetching services:", error);
      });
  }, []);

  const handleServiceChange = (event) => {
    setIsSubmitted(0);

    const serviceId = event.target.value;
    const service = services.find((s) => s["Service ID"] === serviceId);
    if (service) {
      setSelectedService(service.Description);
    } else {
      setSelectedService(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setClientInfo({ ...clientInfo, [name]: value });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!clientInfo.name) {
      newErrors.name = "Name is required";
      isValid = false;
    } else {
      newErrors.name = "";
    }

    if (clientInfo.email && !/^\S+@\S+\.\S+$/.test(clientInfo.email)) {
      newErrors.email = "Invalid email address";
      isValid = false;
    } else {
      newErrors.email = "";
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      // Send a POST request to save the data in the database
      const dataToSave = {
        service: selectedService,
        clientInfo: clientInfo,
      };

      // axios
      //   .post("https://your-api-endpoint-for-saving-data", dataToSave)
      //   .then((response) => {
      //     setIsSubmitted(true);
      //   })
      //   .catch((error) => {
      //     console.error("Error saving data:", error);
      //   });
      console.log(dataToSave);
      alert(JSON.stringify(dataToSave));

      setSelectedService("");
      setClientInfo({
        name: "",
        email: "",
        phoneNumber: "",
        message: "",
      });

      setIsSubmitted(1);
    }
  };

  return (
    <div className="main-div">
      <div className="h1-div">
        <h1 className="header">Yard Squad Services</h1>
      </div>
      <div className="service-list-container">
        <select className="select-dropdown" onChange={handleServiceChange}>
          <option value="">Select a Yard Service</option>
          {services.map((service) => (
            <option
              key={service["Service ID"]}
              value={service["Service ID"]}
              className="select-option"
            >
              {service.Service}
            </option>
          ))}
        </select>
        {selectedService !== false ? (
          <h2 className="selected-service">{selectedService}</h2>
        ) : (
          <h2 className="selected-service">No Service Selected</h2>
        )}

        {selectedService && !isSubmitted && (
          <form onSubmit={handleSubmit} className="client-form">
            <h3 className="client-info-header">
              For Quote Please Enter Your Information
            </h3>
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={clientInfo.name}
              onChange={handleInputChange}
              className="text-input"
            />
            <span className="error-message">{errors.name}</span>
            <input
              type="text"
              name="email"
              placeholder="Email"
              value={clientInfo.email}
              onChange={handleInputChange}
              className="text-input"
              required
            />
            <span className="error-message">{errors.email}</span>
            <input
              type="text"
              name="phoneNumber"
              placeholder="Phone Number"
              value={clientInfo.phoneNumber}
              onChange={handleInputChange}
              className="text-input"
              required
            />
            <textarea
              name="message"
              placeholder="Message"
              value={clientInfo.message}
              onChange={handleInputChange}
              className="textarea-input"
              required
            />
            <button type="submit" className="submit-button">
              Submit
            </button>
          </form>
        )}

        {!selectedService && (
          <div className="movie-frame">
            <iframe
              title="Yard Squad Video"
              width="640"
              height="360"
              src="https://www.youtube.com/embed/sOya-WmLQNU"
              frameBorder="0"
              allowFullScreen
            />
          </div>
        )}
        {isSubmitted ? (
          <div className="success-message">Form submitted successfully!</div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ServiceList;
