import React from "react";
// import ComingSoonPage from "./components/comingsoon";
import ServiceList from "./components/services";

function App() {
  return (
    <div className="App">
      {/* <ComingSoonPage /> */}
      <ServiceList />
    </div>
  );
}

export default App;
